import $ from 'jquery';
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');

import Kennitala from 'kennitala';
window.Kennitala = Kennitala;

import Slick from '../slick';
window.Slick = Slick;

import "../../sass/main.scss"

import React from "react";
import ReactDOM from "react-dom";
import Vorform from "./components/Vorform";
let form = document.getElementById("vorform");
if (form) {
    let formData = $(form).data();
    ReactDOM.render(<Vorform {...formData}/>, form);
}